<style scoped lang='scss'>

</style>

<template>
  <div id="law">
    <div class="width rullMain">
      <div class="title textCenter weight">法律声明</div>
      <p>本网站使用网络跟踪器来改善用户体验及确保网站有效运行。</p>
      <p>本网络跟踪器说明是我们的隐私声明的组成部分。请查阅我们的隐私声明以了解更多关于我们的信息及我们如何保护访问者的信息。</p>
      <p>
        为向您提供个性化更佳、响应更快的服务，我们需要记忆和存储您如何使用本网站的信息。
        我们通过使用名为网络跟踪器的小文本文件来实现这一功能。网络跟踪器包含少量信息，通过网站服务器下载到您的电脑或其他设备上。
        其后您每次访问本网站时，您的网络浏览器会将这些网络跟踪器发回至本网站，以使本网站可以识别用户并记忆您的使用偏好等信息。
      </p>
      <p>无论您何时使用本网站，信息都可通过网络跟踪器和其他技术被收集。如果您使用本网站，即表明您同意我们按本说明所述使用网络跟踪器。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'law',
  components: {},
  data () {
    return {
    }
  },
  computed: {},
  created () {},
  mounted(){
    window.scrollTo({top: 0,left: 0});
  },
  methods: {
  }

}

</script>
